$corS01: #f40803;
$corFF: #FFF;
$corF8: #F8F8F8;
$corF4: #f4f6f9;
$corF3: #f3f5f8;
$corED: #EDEDED;
$corEC: #ecedf2;
$corDD: #DDD;
$corCC: #CCC;
$corBB: #BBB;
$corAA: #AAA;
$cor77: #777;
$cor00: #000;
$corFF00: rgba(255,255,255,0);
$corFF10: rgba(255,255,255,0.1);
$corFF50: rgba(250,250,250,0.5);
$cor0010: rgba(0,0,0,0.1);
$cor0020: rgba(0,0,0,0.2);
$cor0050: rgba(0,0,0,0.5);
$gray10: rgba(236,237,242,0.1);
$gray50: rgba(236,237,242,0.5);

$corGradMid: $corFF;
$corGradEnd: $corFF10;
$corGradStart: $corFF10;

$bgBody         : $corEC;

$bgNav          : $corFF;
$txtCorNav      : $cor00;
$txtCorBody     : $cor00;
$txtCorNavOver  : $cor77;
$bgNetIcon      : $corCC;
$txtCorZap      : $corFF;
$corFooter      : $corFF;
$bgFooter       : #010101;
$bgCorZap       : #28a745;
$borderCorZap   : #1e7e34;
$bgCorTel       : #17a2b8;
$borderCorTel   : #138496;
$corPrice       : $corS01;
$bgOverChip     : $corS01;
$corFixoOver    : $corS01;
$boxBorderCor   : $cor00;
$bgOverAvatar   : $corFF;
$corOverAvatar  : #091120;
$bgBtnsGallery  : $corFF50;
$bgCorSuccess   : #28a745;
$overCorSuccess : #1e7e34;
$bgCorDanger    : #dc3545;
$overCorDanger  : #c82333;
$bgModalHeader  : $corEC;
//$bgPriceGallery  : $corFF40;

.stock-title{
    display:none !important
}




